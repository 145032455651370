import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { Link } from "gatsby"
import * as style from "../styles/service.module.scss"
import Pic01 from "../images/service-section01-img.png"
import Pic01SP from "../images/service-section01-img-sp.png"
import Pic02 from "../images/service-section02-img.png"
import Pic02SP from "../images/service-section02-img-sp.png"
import Pic03 from "../images/service-section03-img.png"
import Pic03SP from "../images/service-section03-img-sp.png"
import Arrows from "../images/service-arrows.png"

const Service = () => {
  return (
    <Layout>
      <Seo
        title="Service | 株式会社アローズ"
        description="私たちができること向かっていく未来。消費者が「欲しい」「買いたい」と思う製品をゼロから開発、消費者が求めるモノがあれば世界中から探しだす。"
      />
      <div id="service">
        <div className={style.mv}>
          <div className={style.inner}>
            <h1>SERVICE</h1>
            <h2 className={style.catch}>私たちのサービス</h2>
          </div>
        </div>
      </div>

      <div className={style.message}>
        <h2>求められる製品を生み出す</h2>
        <p>
          我々が得意とする、
          <br className={style.hiddenPC} />
          ユーザー目線の製品開発と発掘。
          <br />
          市場の動向をみつつ
          <br className={style.hiddenPC} />
          最適な製品やサービスを提供します。
        </p>
      </div>

      <section>
        <div className={style.title}>
          <h3>アパレル</h3>
        </div>
        <div className={style.inner}>
          <div className={style.items}>
            <h4>日本人目線の品質基準</h4>
            <p>
              オリジナリティｰ溢れる独自商品を自社にて企画・開発・輸入し、日本国内の検品基準を熟知した検品会社の専門スタッフによる商品の検査・検品を徹底して行っております。
            </p>
            <div className={style.chart}>
              <div className={style.box1}>クライアント</div>
              <div className={style.arrows}>
                <img src={Arrows} alt="矢印" />
              </div>
              <div className={style.box2}>
                <p>アローズ</p>
                <div className={style.innerBox}>企画・開発</div>
                <div className={style.innerBox}>製造・OEM</div>
              </div>
              <div className={style.arrows}>
                <img src={Arrows} alt="矢印" />
              </div>
              <div className={style.box3}>
                <p>製造メーカー</p>
                <div className={style.innerBox}>国内</div>
                <div className={style.innerBox}>海外</div>
              </div>
            </div>
            <p className={style.explain}>
              サービスフローイメージを分かり易い図版とテキストで説明。テキストが入りますテキストが入りますテキストが入りますテキストが入りますテキストが入りますテキストが入りますテキストが入りますテキストが入りますテキストが入りますテキストが入りますテキストが入ります
            </p>
            <p>
              <strong>主要取引先</strong>
              <br />
              〇〇〇株式会社・株式会社△△△△・〇〇〇株式会社・株式会社△△△△・〇〇〇株式会社・株式会社△△△△・〇〇〇株式会社・株式会社△△△△
            </p>
            <Link
              to="/contact"
              state={{ types: "apparel" }}
              className={`${style.toDetail} pl_slide`}
            >
              アパレル事業についてのお問い合わせ
            </Link>
          </div>
          <div className={style.img}>
            <img src={Pic01} alt="アパレル" className={style.hiddenSP} />
            <img src={Pic01SP} alt="アパレル" className={style.hiddenPC} />
          </div>
        </div>
      </section>

      <section>
        <div className={style.title}>
          <h3>家電</h3>
        </div>
        <div className={style.inner}>
          <div className={style.items}>
            <h4>
              イメージを形にする
              <br />
              スピード・確かな品質・低価格
            </h4>
            <p>
              関連会社である「株式会社丸隆」リソースを100%活用し、
              <br />
              自社オリジナル家電製品を低コストで作ることが出来ます。
            </p>
            <div className={style.chart}>
              <div className={style.box1}>クライアント</div>
              <div className={style.arrows}>
                <img src={Arrows} alt="矢印" />
              </div>
              <div className={style.box2}>
                <p>アローズ</p>
                <div className={style.innerBox}>企画・開発</div>
                <div className={style.innerBox}>製造・OEM</div>
              </div>
              <div className={style.arrows}>
                <img src={Arrows} alt="矢印" />
              </div>
              <div className={style.box4}>株式会社丸隆</div>
            </div>
            <p className={style.explain}>
              サービスフローイメージを分かり易い図版とテキストで説明。テキストが入りますテキストが入りますテキストが入りますテキストが入りますテキストが入りますテキストが入りますテキストが入りますテキストが入りますテキストが入りますテキストが入りますテキストが入ります
            </p>
            <p>
              <strong>主要取引先</strong>
              <br />
              〇〇〇株式会社・株式会社△△△△・〇〇〇株式会社・株式会社△△△△・〇〇〇株式会社・株式会社△△△△・〇〇〇株式会社・株式会社△△△△
            </p>
            <Link
              to="/contact"
              state={{ types: "appliances" }}
              className={`${style.toDetail} pl_slide`}
            >
              家電事業についてのお問い合わせ
            </Link>
          </div>
          <div className={style.img}>
            <img src={Pic02} alt="家電" className={style.hiddenSP} />
            <img src={Pic02SP} alt="家電" className={style.hiddenPC} />
          </div>
        </div>
      </section>

      <section>
        <div className={style.title}>
          <h3>ネット通販</h3>
        </div>
        <div className={style.inner}>
          <div className={style.items}>
            <h4>小物・雑貨・スマートフォンケース</h4>
            <p>
              オリジナリティｰ溢れる独自商品を自社にて企画・開発・輸入し、日本国内の検品基準を熟知した検品会社の専門スタッフによる商品の検査・検品を徹底して行っております。
            </p>
            <div className={style.chart}>
              <div className={style.box2}>
                <p>アローズ</p>
                <div className={style.innerBox}>企画・開発</div>
                <div className={style.innerBox}>製造・OEM</div>
              </div>
              <div className={style.arrows}>
                <img src={Arrows} alt="矢印" />
              </div>
              <div className={style.box4}>スターズマーケット</div>
            </div>
            <p className={style.explain}>
              サービスフローイメージを分かり易い図版とテキストで説明。テキストが入りますテキストが入りますテキストが入りますテキストが入りますテキストが入りますテキストが入りますテキストが入りますテキストが入りますテキストが入りますテキストが入りますテキストが入ります
            </p>
            <p>
              <strong>主要取引先</strong>
              <br />
              〇〇〇株式会社・株式会社△△△△・〇〇〇株式会社・株式会社△△△△・〇〇〇株式会社・株式会社△△△△・〇〇〇株式会社・株式会社△△△△
            </p>
            <Link
              to="/contact"
              state={{ types: "online" }}
              className={`${style.toDetail} pl_slide`}
            >
              ネット通販事業についてのお問い合わせ
            </Link>
          </div>
          <div className={style.img}>
            <img src={Pic03} alt="ネット通販" className={style.hiddenSP} />
            <img src={Pic03SP} alt="ネット通販" className={style.hiddenPC} />
          </div>
        </div>
      </section>

      <div className={`footNav`}>
        <Link to="/mind" className={`mind`}>
          <p className={`mtM`}>MIND</p>
          <p className={`mbM`}>
            <span>MORE</span>
          </p>
        </Link>
        <Link to="/about" className={`about`}>
          <p className={`mtM`}>ABOUT</p>
          <p className={`mbM`}>
            <span>MORE</span>
          </p>
        </Link>
        <Link to="/recruit" className={`recruit`}>
          <p className={`mtM`}>RECRUIT</p>
          <p className={`mbM`}>
            <span>MORE</span>
          </p>
        </Link>
      </div>
    </Layout>
  )
}

export default Service
